import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/LayoutComponent"
import Seo from "../components/SeoComponent"
import "../assets/234.css"

const Recomend234 = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "preporuka" })}
        canonicalUrl={"recomend-234"}
      />
      <div className="rec-234">
        <StaticImage
          className="rec-234-img"
          src="../images/recomend/234_RECOMMONDATION.jpg"
          alt="234 recomendation"
          layout="constrained"
          placeholder="blurred"
        />
      </div>
    </Layout>
  )
}

export default Recomend234
